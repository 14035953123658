<template>
  <div class="forgot-password">
    <div class="container">
      <div class="forgot-password-card">
        <div class="title">Restoring your password</div>

        <p class="mb-2">
          Enter your email address and we will send you a reset link.
        </p>

        <ValidationObserver ref="observer"
                            v-slot="{ passes }"
                            slim>
          <form @submit.prevent="passes(runCaptcha)">
            <base-textbox label="Email"
                          ref="email"
                          rules="required|email"
                          v-model="email"
                          placeholder="Enter your email address" />
            <div class="tools" :class="$mq">
              <base-button type="submit"
                           class="submit-button"
                           color="primary"
                           size="big"
                           :loading="loading">Restore</base-button>
                <router-link class="return-link"
                             :to="{ name: 'login' }">Return to Login</router-link>
            </div>
          </form>
        </ValidationObserver>

        <base-alert v-if="showMessage" type="success">If your email exists in our database, you'll receive a reset link. If you are using google authentication, please use google sign in method.</base-alert>

        <vue-recaptcha ref="recaptcha"
                       size="invisible"
                       :sitekey="recaptchaKey"
                       @verify="submitForm"
                       @expired="onCaptchaExpired" />
      </div>
      <recaptcha-disclaimer />
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import RecaptchaDisclaimer from '@/components/Auth/AuthRecaptchaDisclaimer.vue'
import auth from '@/services/auth.js'

export default {
  components: {
    VueRecaptcha,
    RecaptchaDisclaimer
  },

  metaInfo: {
    title: 'Forgot Password'
  },

  data () {
    return {
      email: '',
      recaptcha_token: null,
      loading: false,
      recaptchaKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
      showMessage: false
    }
  },

  mounted () {
    this.$refs.email.focus()
  },

  methods: {
    runCaptcha () {
      this.loading = true
      this.$refs.recaptcha.execute()
    },

    async submitForm (recaptcha_token) {
      await auth.sendResetPasswordLink({
        email: this.email,
        recaptcha_token
      })
      // TODO: Why it's not required in Login & it works there
      this.$refs.recaptcha.reset()

      this.email = ''
      this.showMessage = true

      this.$refs.observer.reset()

      this.loading = false
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
  .forgot-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 10px 0;
    background-image: linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%);

    .container {
      max-width: 500px;
    }

    @media (max-width: 768px) {
      .container {
        width: 100%;
        max-width: none;
      }
    }

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 14px;

      font-size: 20px;
      font-weight: 700;
    }

    .forgot-password-card {
      padding: 40px;
      margin-bottom: 10px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 5px 5px 20px 0 rgba(36, 50, 66, 0.1);
    }

    .tools {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .submit-button {
        width: 200px;
      }

      .return-link {
        padding: 10px 0;
        font-size: 16px;
      }

      &.mobile {
        .submit-button {
          width: 150px;
        }

        .return-link {
          font-size: 14px;
        }
      }
    }
  }
</style>
